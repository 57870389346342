/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  ModalOverlay,
  ModalContent,
  ModalTitle,
  ModalBody,
  FeeInfo,
  FeeSection,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
} from './styles';

type CycleType = 'semanal' | 'quinzenal' | 'mensal' | '45dias';

interface SimpleDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cycleType: CycleType;
  hasRevShare: boolean;
}

const SimpleDialog = ({
  isOpen,
  onClose,
  onConfirm,
  cycleType,
  hasRevShare,
}: SimpleDialogProps) => {
  if (!isOpen) return null;

  const getCycleDescription = () => {
    switch (cycleType) {
      case 'semanal':
        return 'Ciclo de 7 dias performados pelo Usuário (Autônomo), a antecipação ao Usuário (Autônomo) é feita a partir do 2º dia após o início do Ciclo para antecipar o primeiro dia util performado por ele e o pagamento à Plific pela Contratada é em 3 dias depois do final do Ciclo (no 10º dia).';
      case 'quinzenal':
        return 'Ciclo de 15 dias performados pelo Usuário (Autônomo), a antecipação ao Usuário (Autônomo) é feita a partir do 2º dia após o início do Ciclo para antecipar o primeiro dia util performado por ele e o pagamento à Plific pela Contratada é em 3 dias depois do final do Ciclo (no 18º dia).';
      case 'mensal':
        return 'Ciclo de 30 dias performados pelo Usuário (Autônomo), a antecipação ao Usuário (Autônomo) é feita a partir do 2º dia após o início do Ciclo para antecipar o primeiro dia util performado por ele e o pagamento à Plific pela Contratada é em 3 dias depois do final do Ciclo (no 33º dia).';
      case '45dias':
        return 'Ciclo de 45 dias performados pelo Usuário (Autônomo), a antecipação ao Usuário (Autônomo) é feita a partir do 2º dia após o início do Ciclo para antecipar o primeiro dia util performado por ele e o pagamento à Plific pela Contratada é em 3 dias depois do final do Ciclo (no 48º dia).';
      default:
        return '';
    }
  };

  const getFeeInfo = () => {
    const baseFees = {
      semanal: 3.14,
      quinzenal: 5.09,
      mensal: 9.43,
      '45dias': 13.48,
    };

    const baseFee = baseFees[cycleType] || 0;
    const totalFee = baseFee;

    return {
      withRevShare: `${totalFee.toFixed(2)}%`,
      baseRate: `${baseFee.toFixed(2)}%`,
    };
  };

  const fees = getFeeInfo();
  const cycleDescription = getCycleDescription();
  const cycleTitle = cycleType.charAt(0).toUpperCase() + cycleType.slice(1);

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Confirmação de Taxas</ModalTitle>
        <ModalBody>
          {hasRevShare ? (
            <FeeInfo>
              <FeeSection>
                <strong style={{ fontWeight: 600 }}>Com Rev Share:</strong>
                <p>Seu contrato terá as seguintes características:</p>
                <p style={{ fontWeight: 600 }}>{cycleTitle}</p>
                <p>- {cycleDescription}</p>
                <p>
                  - Uma taxa variável de{' '}
                  <strong style={{ fontWeight: 600 }}>
                    {fees.withRevShare}
                  </strong>{' '}
                  sobre o Valor Solicitado de Antecipação, somada ao valor fixo
                  de <strong style={{ fontWeight: 600 }}>R$ 0,99</strong> por
                  cada antecipação ("Taxa Fixa"). A Contratante opta por cobrar
                  dos Usuários uma taxa de{' '}
                  <strong style={{ fontWeight: 600 }}>0,5%</strong> sobre o
                  Valor Solicitado de Antecipação ("Taxa de Cashback"), já
                  incluída no valor da taxa variável de{' '}
                  <strong style={{ fontWeight: 600 }}>
                    {fees.withRevShare}
                  </strong>
                  .
                </p>
              </FeeSection>
            </FeeInfo>
          ) : (
            <FeeInfo>
              <FeeSection>
                <strong style={{ fontWeight: 600 }}>Sem Revenue Share:</strong>
                <p>Seu contrato terá as seguintes características:</p>
                <p style={{ fontWeight: 600 }}>{cycleTitle}</p>
                <p>- {cycleDescription}</p>
                <p>
                  - Uma taxa variável de{' '}
                  <strong style={{ fontWeight: 600 }}>{fees.baseRate}</strong>{' '}
                  sobre o Valor Solicitado de Antecipação, somada ao valor fixo
                  de <strong style={{ fontWeight: 600 }}>R$ 0,99</strong> por
                  cada antecipação ("Taxa Fixa").
                </p>
              </FeeSection>
            </FeeInfo>
          )}
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
          <PrimaryButton onClick={onConfirm}>Confirmar e Enviar</PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};

export default SimpleDialog;
